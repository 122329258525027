a:active,
a:hover {
  outline: 0;
}
md-icon.dib-logo #rect3395 {
  fill: #ffd54f !important;
}
.DibHeader {
  background-color: #fff !important;
  position: fixed;
  min-height: 68px;
  z-index: 10;
}
.header-ctn {
  max-width: 90rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
}
.dib-toolbar {
  background-color: #fff;
}
.toolbar-user-avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  vertical-align: middle;
  margin: 0em 1em;
}
.DibHeader-container {
  height: 100%;
}
.header-logo {
  display: flex;
  cursor: pointer;
  width: 100%;
}
.dib-logo {
  height: 48px;
  width: 100%;
}
.logo-ufpe {
  height: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
.h-100 {
  height: 100px !important;
}
.h-120 {
  height: 120px !important;
}
.DibHeader-link {
  text-decoration: none;
  padding: 0 15px;
  display: inline-block;
  line-height: 68px;
  font-family: "Raleway-Semi-Bold", sans-serif;
  font-size: 16px;
  color: #000 !important;
  transition: 250ms background-color;
}
.has-sub {
  cursor: pointer;
}
.has-sub md-icon > svg {
  fill: #424242;
}
.sub-menu {
  transition: 250ms background-color;
  text-decoration: none;
  padding: 14px 16px 15px;
  line-height: 20px;
  font-weight: normal;
  background-color: #ffd54f;
  color: #000 !important;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
}
.login-link {
  text-decoration: none;
  padding: 0 10px;
  display: inline-block;
  transition: 250ms background-color;
  line-height: 68px;
  font-size: 14px;
  font-weight: normal;
  font-family: "Raleway", sans-serif;
  color: #424242 !important;
}
.sub-menu.active,
.sub-menu:hover,
.sub-menu.active:hover {
  background-color: #ffe082 !important;
}
.DibHeader-link:hover,
.login-link:hover,
.dib-item-link:hover,
.DibHeader-link.active:hover,
.login-link.active:hover,
.dib-item-link.active:hover {
  color: #000 !important;
  background-color: #ffd54f !important;
}
.DibHeader-link.active,
.login-link.active,
.dib-item-link.active,
.sub-menu.active {
  outline: none;
}
.dib-menu {
  min-width: 0px;
  margin-left: 0.5em;
}
.main-menu-visible {
  width: 232px;
}
.menu-visible {
  padding: 0 15px;
}
.avatar-button {
  width: 62px !important;
  height: 62px !important;
}
.avatar-button md-icon {
  width: 48px !important;
  height: 48px !important;
}
.avatar-button md-icon > svg {
  fill: #888888;
}
.avatar-circle {
  width: 100px;
  height: 100px;
  background-color: #ffd54f;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.avatar-circle:hover {
  background-color: #ffb300 !important;
}
.initials {
  color: #424242;
}
a:active,
a:hover {
  outline-width: 0;
}
html {
  box-sizing: border-box;
}
.page-section__title,
h2 {
  font-size: 1.4375rem;
}
a,
a:hover,
a:visited {
  outline: none;
  text-decoration: none;
}
button {
  background: none;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
button[type="button"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input:-webkit-autofill,
select:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
  -webkit-transition-delay: 9999s;
}
[v-cloak] {
  display: none;
}
.bordered-content {
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.2);
}
.bordered-content,
.bordered-content--top {
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.2);
}
.bordered-content--bottom {
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 1180px) {
  .global-header__logo-link {
    margin-left: 0;
  }
}
.global-header__logo-link:focus {
  color: #757575;
}
.global-header__logo,
.global-header__logo--light {
  display: block;
  height: 1.75rem;
  width: 11.625rem;
}
.global-header__logo--light {
  display: none;
  fill: #fff;
}
.global-header__select-wrapper {
  display: none;
  margin: 0.9375rem 0 0 1.25rem;
}
@media screen and (min-width: 1001px) {
  .global-header__select-wrapper {
    display: inline-block;
  }
}
.global-header__select {
  background: transparent;
  max-width: 13.125rem;
}
@media screen and (min-width: 1001px) {
  .global-navigation {
    float: right;
    margin-right: 1.9375rem;
    position: relative;
  }
}
.global-navigation--mobile {
  float: none;
  height: auto;
  margin-right: 0;
}
.global-navigation__content {
  display: none;
}
@media screen and (min-width: 1180px) {
  .global-navigation__content {
    display: -ms-flexbox;
    display: flex;
    float: left;
    margin-right: 0.5rem;
  }
}
.global-navigation__content--mobile {
  display: block;
  padding-bottom: 5rem;
}
@media screen and (min-width: 1180px) {
  .global-navigation__content--mobile {
    display: none;
  }
}
.global-navigation-section__label,
.global-navigation__link {
  color: #424242;
  padding: 0 0.875rem;
}
@media screen and (min-width: 1180px) {
  .global-navigation-section__label,
  .global-navigation__link {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}
.global-navigation-submenu__link,
.global-navigation-submenu__link:visited,
.global-navigation__link,
.global-navigation__link:visited {
  color: #424242;
}
.global-navigation-submenu__link:focus,
.global-navigation-submenu__link:hover,
.global-navigation__link:focus,
.global-navigation__link:hover {
  background: #ffe082;
  color: #000;
  outline: none;
}
.global-navigation__link {
  display: inherit;
  padding: 0.75rem 1.5rem;
}
.global-navigation__link:focus,
.global-navigation__link:hover {
  background: #ffe082;
  outline: none;
}
@media screen and (min-width: 1180px) {
  .global-navigation__link:focus,
  .global-navigation__link:hover {
    background: #f5f5f5;
  }
}
@media screen and (min-width: 1180px) {
  .global-navigation__link {
    padding: 0 0.875rem;
  }
}
@media screen and (min-width: 1180px) {
  .global-navigation-section {
    overflow: hidden;
    position: relative;
  }
  .global-navigation-section.menu-active,
  .global-navigation-section:hover {
    filter: drop-shadow(0 0 0.1875rem rgba(0, 0, 0, 0.15));
    overflow: visible;
  }
  .global-navigation-section.menu-active .global-navigation-submenu,
  .global-navigation-section:hover .global-navigation-submenu {
    height: auto;
  }
  .global-navigation-section.menu-active .global-navigation-section__label,
  .global-navigation-section:hover .global-navigation-section__label {
    background: #ffd54f;
  }
  .global-navigation-section.menu-active .global-navigation__icon:after,
  .global-navigation-section.menu-active .global-navigation__icon:before,
  .global-navigation-section:hover .global-navigation__icon:after,
  .global-navigation-section:hover .global-navigation__icon:before {
    background: #424242;
  }
  .global-navigation-section.menu-active .global-navigation__icon:before,
  .global-navigation-section:hover .global-navigation__icon:before {
    transform: rotate(-45deg);
  }
  .global-navigation-section.menu-active .global-navigation__icon:after,
  .global-navigation-section:hover .global-navigation__icon:after {
    transform: rotate(45deg);
  }
}
.global-navigation-section__label {
  display: none;
}
@media screen and (min-width: 1180px) {
  .global-navigation-section__label {
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding-right: 0.375rem;
  }
}
.global-navigation__icon {
  height: 0.625rem;
  margin-left: 0.375rem;
  margin-top: 0.125rem;
  position: relative;
  width: 1.4375rem;
}
.global-navigation__icon:after,
.global-navigation__icon:before {
  background: #424242;
  content: "";
  height: 0.5rem;
  position: absolute;
  transition: transform 0.2s cubic-bezier(0.4, 0, 1, 1);
  width: 0.125rem;
}
.global-navigation__icon:before {
  right: 0.625rem;
  transform: rotate(45deg);
}
.global-navigation__icon:after {
  right: 0.875rem;
  transform: rotate(-45deg);
}
@media screen and (min-width: 1180px) {
  .global-navigation-submenu {
    background: #f5f5f5;
    height: 0;
    left: 0;
    max-width: 17.5rem;
    min-width: 14.375rem;
    overflow: hidden;
    position: absolute;
    top: 3.8125rem;
  }
}
.global-navigation-submenu__level:before {
  background: #e0e0e0;
  content: "";
  display: block;
  height: 0.0625rem;
  margin: 0.5rem 1.5rem;
}
@media screen and (min-width: 1180px) {
  .global-navigation-submenu__level:before {
    background: #bdbdbd;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media screen and (min-width: 1180px) {
  .global-navigation-submenu__level:first-child:before {
    content: normal;
  }
}
.global-navigation-submenu__link {
  display: block;
  padding: 0.75rem 1.5rem;
}
@media screen and (min-width: 1180px) {
  .global-navigation-submenu__link {
    padding: 0.75rem 1.75rem 0.75rem 0.875rem;
  }
}
.counters-head {
  text-decoration: none;
  padding: 0 12px;
  display: inline-block;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #333333 !important;
  margin: 13px 0 0 0;
}
.counter-users {
  margin-left: 0;
  padding-bottom: 0;
}
